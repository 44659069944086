// AdminMenu.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminMenu.css'; // Optional CSS file for styling

const AdminMenu = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="admin-menu-container">
      <h1>Admin Menu</h1>
      <div className="admin-menu-buttons">
        <button onClick={() => handleNavigation('/admin/add-product')}>Add a Product</button>
        <button onClick={() => handleNavigation('/admin/edit-product')}>Edit a Product</button>
        <button onClick={() => handleNavigation('/admin/edit-order')}>Edit an Order</button>
        {/* Add more buttons as needed */}
      </div>
    </div>
  );
};

export default AdminMenu;
