import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner'; // Make sure to import your LoadingSpinner component
import './AdminAddProduct.css';

const AdminAddProduct = () => {
  const [productName, setProductName] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [sku, setSku] = useState('');
  const [tags, setTags] = useState('');
  const [price, setPrice] = useState('');
  const [token, setToken] = useState('');
  const [productImage, setProductImage] = useState(null);
  const [prizes, setPrizes] = useState([{ name: '', description: '', quantity: '', image: null }]);
  const [active, setActive] = useState(true);
  const [doubleChance, setDoubleChance] = useState(false);
  const [preorder, setPreorder] = useState(false);
  const [arrival, setArrival] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handlePrizeChange = (index, field, value) => {
    const newPrizes = [...prizes];
    newPrizes[index][field] = value;
    setPrizes(newPrizes);
  };

  const handleFileChange = (index, file) => {
    const newPrizes = [...prizes];
    newPrizes[index].image = file;
    setPrizes(newPrizes);
  };

  const addPrizeField = () => setPrizes([...prizes, { name: '', description: '', quantity: '', image: null }]);

  const deletePrizeField = (index) => {
    const newPrizes = prizes.filter((_, i) => i !== index);
    setPrizes(newPrizes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading when submit is clicked

    const formData = new FormData();
    formData.append('name', productName);
    formData.append('quantity', quantity);
    formData.append('sku', sku);
    formData.append('tags', tags);
    formData.append('price', price);
    formData.append('token', token);
    formData.append('productImage', productImage);
    formData.append('active', active);
    formData.append('doubleChance', doubleChance);
    formData.append('preorder', preorder);

    if (preorder) {
      formData.append('arrival', arrival);
    }

    formData.append('prizes', JSON.stringify(prizes.map((prize) => ({
      name: prize.name,
      description: prize.description,
      quantity: prize.quantity,
    }))));

    prizes.forEach((prize, index) => {
      formData.append(`prizeImage${index}`, prize.image);
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/add-product`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setMessage('Product and prizes added successfully!');
        setTimeout(() => {
          navigate('/admin/menu'); // Redirect to admin menu after success message
        }, 1000); // Redirect after 1 second to show success message
      } else {
        setMessage('Failed to add product and prizes');
      }
    } catch (error) {
      console.error('Error adding product and prizes:', error);
      setMessage('Error adding product and prizes');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="admin-add-product-form">
      <h2>Add New Product</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div>
          <label>Product Name:</label>
          <input type="text" value={productName} onChange={(e) => setProductName(e.target.value)} required />
        </div>
        <div>
          <label>Quantity:</label>
          <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
        </div>
        <div>
          <label>SKU:</label>
          <input type="text" value={sku} onChange={(e) => setSku(e.target.value)} required />
        </div>
        <div>
          <label>Tags (comma-separated):</label>
          <input type="text" value={tags} onChange={(e) => setTags(e.target.value)} required />
        </div>
        <div>
          <label>Price:</label>
          <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
        </div>
        <div>
          <label>Token Value:</label>
          <input type="text" value={token} onChange={(e) => setToken(e.target.value)} required />
        </div>
        <div>
          <label>Product Image:</label>
          <input type="file" onChange={(e) => setProductImage(e.target.files[0])} required />
        </div>

        <div>
          <label>Active:</label>
          <input type="checkbox" checked={active} onChange={(e) => setActive(e.target.checked)} />
        </div>
        <div>
          <label>Double Chance:</label>
          <input type="checkbox" checked={doubleChance} onChange={(e) => setDoubleChance(e.target.checked)} />
        </div>
        <div>
          <label>Preorder:</label>
          <input type="checkbox" checked={preorder} onChange={(e) => setPreorder(e.target.checked)} />
        </div>
        {preorder && (
          <div>
            <label>Arrival Date:</label>
            <input type="text" value={arrival} onChange={(e) => setArrival(e.target.value)} />
          </div>
        )}

        <div>
          <h3>Prizes</h3>
          {prizes.map((prize, index) => (
            <div key={index} className="prize-field">
              <input
                type="text"
                placeholder="Prize Name"
                value={prize.name}
                onChange={(e) => handlePrizeChange(index, 'name', e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Description"
                value={prize.description}
                onChange={(e) => handlePrizeChange(index, 'description', e.target.value)}
                required
              />
              <input
                type="number"
                placeholder="Quantity"
                value={prize.quantity}
                onChange={(e) => handlePrizeChange(index, 'quantity', e.target.value)}
                required
              />
              <input
                type="file"
                onChange={(e) => handleFileChange(index, e.target.files[0])}
                required
              />
              <button type="button" onClick={() => deletePrizeField(index)}>
                Delete Prize
              </button>
            </div>
          ))}
        </div>
        <button type="button" onClick={addPrizeField}>
          Add Another Prize
        </button>
        
        {/* Show LoadingSpinner if loading, else show Submit button */}
        {loading ? (
          <LoadingSpinner />
        ) : (
          <button type="submit">Submit</button>
        )}
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AdminAddProduct;