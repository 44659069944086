// PaymentForm.js
import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Make sure you've installed react-router-dom
import './ShippingPaymentForm.css';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner'; // Ensure you have this component created

function ShippingPaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // Hook for programmatic navigation

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  const shipmentId = useSelector((state) => state.payment.shipmentId);
  const shipmentOrders = useSelector((state) => state.payment.shipmentOrders);

  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [isFormLoading, setIsFormLoading] = useState(true); // New loading state
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentRequestAvailable, setPaymentRequestAvailable] = useState(false);

  const shippingFee = 500;

  useEffect(() => {
    const initializePaymentRequest = async () => {
      if (stripe) {

        const pr = stripe.paymentRequest({
          country: 'SG',
          currency: 'sgd',
          total: {
            label: 'Total',
            amount: shippingFee, // Ensure totalCost is in cents
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
    
        pr.canMakePayment().then(result => {
          if (result && result.applePay) {
            setPaymentRequest(pr);
            setPaymentRequestAvailable(true);
          }
        }).catch(error => {
          console.error("Payment request error: ", error);
        });
    
        pr.on('token', async (e) => {
          // Handle the submission of payment details
          const { token, error } = e;
    
          // Process the payment using the token.id...
          // Similar to handling confirmation in your existing handleSubmit
    
          if (error) {
            console.log(`Payment failed: ${error.message}`);
            e.complete('fail');
          } else {
            console.log('Payment succeeded!');
            // Navigate or update state as needed
            const response = await fetch(`${backendUrl}/api/order/update-shipment/${shipmentId}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include'
            });
        
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            e.complete('success');
            navigate(`/orders`, { state: { shipmentSuccess: true } });
        }
        });
      }
    }

    initializePaymentRequest();

  }, [stripe, navigate, backendUrl, shipmentId]);
  

  useEffect(() => {
    if (stripe) {
      // Assume if stripe is loaded, elements might be ready soon after
      // This is a simplification and might not accurately reflect the loaded state of the PaymentElement
      const timer = setTimeout(() => setIsFormLoading(false), 1000); // Wait for 1 second before assuming elements are loaded
      return () => clearTimeout(timer);
    }
  }, [stripe]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true); // Start loading
  
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsLoading(false); // Stop loading if Stripe.js hasn't loaded
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // You still might need to specify a return_url for fallback, but it's used less often.
        return_url: `${window.location.origin}/orders`,
      },
      redirect: 'if_required', // This instructs Stripe to only redirect if necessary.
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
          handlePaymentStatusCheck(shipmentId); // Check the payment status
      }
    }
  };

  const handlePaymentStatusCheck = async (shipmentId) => {
    try {
        const response = await fetch(`${backendUrl}/api/shipment/payment-status/${shipmentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const data = await response.json();

        if (data.paymentStatus === 'succeeded') {
            navigate(`/orders`, { state: { shipmentSuccess: true } });
        } else {
            console.log('Payment not yet completed. Retrying...');
            setTimeout(() => handlePaymentStatusCheck(shipmentId), 2000); // Retry after 2 seconds
        }
    } catch (error) {
        console.error('Error checking shipment payment status:', error);
    }
  };


  return (
    <div>
        <NavBar />
        {isLoading && (
            <div className="spinner-overlay">
              <LoadingSpinner />
              <p style={{color: "white"}}>Processing your payment...</p>
            </div>
        )}
        <div className="gradient-background">
            <div className="shipping-payment-container">
                <div className="shipping-payment-info">
                    <h2 className="shipping-payment-details">Payment Details</h2>
                    {shipmentOrders && (
                        <>
                            <p className='shipping-order-id p-text'>{shipmentOrders.join(', ')}</p>
                            <p className='shipping-cost p-text'>Shipping Cost: ${(shippingFee/100).toFixed(2)}</p>
                        </>
                    )}
                </div>
                <div className="shipping-payment-form">
                  {/* {paymentRequestAvailable && (
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                  )} */}
                  <form className="shipping-stripe-payment-element-form" onSubmit={handleSubmit}>
                    <PaymentElement />
                    <button type="submit" className="shipping-pay-button" disabled={!stripe || isLoading}>
                      {isLoading ? 'Processing...' : 'Pay'}
                    </button>
                  </form>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ShippingPaymentForm;