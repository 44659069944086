import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentState } from './redux/slices/paymentSlice';
import NavBar from './NavBar';
import './OrderDetailsPage.css';
import ShipmentSuccessPopup from './ShipmentSuccessPopup'; // You'll need to create this component
import ShipmentPopup from './ShipmentPopup';
import LoadingSpinner from './LoadingSpinner';
import tokenIcon from './surprize_token.png';
import SelfCollectPopup from './SelfCollectPopup'; // You'll create this component

function OrderDetailsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const username = useSelector(state => state.user.username);
  const [prizes, setPrizes] = useState([]);
  const [showShipmentPopup, setShowShipmentPopup] = useState(false);
  const [showSelfCollectPopup, setShowSelfCollectPopup] = useState(false); // State to control self-collect popup
  const [userShippingAddress, setUserShippingAddress] = useState({});
  const [order, setOrder] = useState(location.state?.order || null);
  const [fetchOrderAgain, setFetchOrderAgain] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false); // State to handle access denied

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const { orderId } = useParams(); // Use destructuring directly

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [])

  useEffect(() => {    
    // Retrieve the pendingPaymentOrderId from sessionStorage
    const pendingPaymentOrderId = sessionStorage.getItem('pendingPaymentOrderId');
  
    // Verify payment if the pending payment orderId matches the current page's orderId
    if (pendingPaymentOrderId && pendingPaymentOrderId === orderId) {
      verifyPaymentAndShipment(pendingPaymentOrderId); // Assuming this function encapsulates the fetch call to your verification endpoint
      sessionStorage.removeItem('pendingPaymentOrderId'); // Clean up to prevent re-verifying in the future
    }
  }, [orderId]); // Add any other dependencies as needed
  
  const verifyPaymentAndShipment = async (orderId) => {
    // Fetch call to your verification endpoint, similar to the previously discussed approach
    const response = await fetch(`${backendUrl}/api/payment/verify-shipment/${orderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });
    
    const data = await response.json();
    
    if (data.success) {
      // Handle successful verification (e.g., update UI to show success message)
      setFetchOrderAgain(!fetchOrderAgain);
      // Optionally, trigger any state updates or redirections as necessary
    } else {
      // Handle failed verification (e.g., show error message)
      console.error(data.message);
      // Optionally, trigger UI updates to reflect the error state
    }
  };

  useEffect(() => {
    fetchOrderDetails(orderId);
  }, [fetchOrderAgain])

  useEffect(() => {
    if (!order && orderId) {
      fetchOrderDetails(orderId); // Only fetch order if it's not provided through location state and orderId is available
    }
  }, [orderId, fetchOrderAgain]); // Dependency array should include orderId to avoid unnecessary fetches

  useEffect(() => {
    if (order) {
      fetchPrizes(); // Fetch prizes only if the order exists
    }
  }, [order]); // Dependency on order ensures fetchPrizes runs only after order is set

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await fetch(`${backendUrl}/api/order/${orderId}`, {
        method: 'POST', // Change to POST
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include',
        body: JSON.stringify({ username }) // Send username in the request body
      });
      if (response.ok) {
        const fetchedOrder = await response.json();
        // Update your state with the fetched order details
        // Note: Ensure your state can handle the fetched order structure or adjust accordingly
        setOrder(fetchedOrder);
      } else if (response.status === 403) {
        setAccessDenied(true); // Set access denied state
      } else {
        console.error('Failed to fetch order details');
        // Handle failure
      }
    } catch (error) {
      console.error('Error fetching order details:', error);
      // Handle error
    }
  };

  const fetchPrizes = async () => {
    if (!order) return;
  
    try {
      const response = await fetch(`${backendUrl}/api/prize/fetch-prizes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productId: order.productId._id, // Assuming this is how you store product ID in the order
          selectedPrizes: order.selectedPrizes,
        }),
        credentials: 'include'
      });
      if (response.ok) {
        const { prizes } = await response.json();
        setPrizes(prizes);
      } else {
        console.error('Failed to fetch prizes');
      }
    } catch (error) {
      console.error('Error fetching prizes:', error);
    }
  };

  const fetchUserShippingAddress = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/users/shipping-address/${username}`, {
        method: 'GET',
        credentials: 'include', // for session-based authentication
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming token is stored in localStorage
        },
      });
      if (response.ok) {
        const address = await response.json();
        setUserShippingAddress(address);
      } else {
        console.error('Failed to fetch user shipping address');
      }
    } catch (error) {
      console.error('Error fetching user shipping address:', error);
    }
  };

  const formatDateToSGT = (utcDate) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Asia/Singapore',
      hour12: false
    };
  
    return new Intl.DateTimeFormat('en-SG', options).format(new Date(utcDate));
  };

  const formatShipByDate = (utcDate) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'Asia/Singapore',
      hour12: false
    };
  
    return new Intl.DateTimeFormat('en-SG', options).format(new Date(utcDate));
  };

  const handleArrangeShipmentClick = () => {
    fetchUserShippingAddress();
    setShowShipmentPopup(true);
  };

  // Function to handle the "Self-Collect" click, opening the confirmation popup
  const handleSelfCollectClick = () => {
    setShowSelfCollectPopup(true);
  };

  // Function to confirm self-collection
  const confirmSelfCollect = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/order/self-collect/${orderId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });

      if (response.ok) {
        const updatedOrder = await response.json();
        setOrder(updatedOrder); // Update the order with the new state
        setShowSelfCollectPopup(false); // Close the popup
      } else {
        console.error('Failed to update order for self-collect');
      }
    } catch (error) {
      console.error('Error during self-collect:', error);
    }
  };

  const renderPrizes = () => (
    <>
    <h2 className="prizes-won-title">Prizes Won</h2>
    <div className="order-details-selected-prizes">{order.selectedPrizes.sort().join(', ')}</div>
    <div className="order-details-prizes-container">
      {prizes.map((prize) => (
        <div className="order-details-prize-card" key={prize._id}>
          <img className="order-details-prize-image" src={`${backendUrl}/api/images/${prize.image}`} alt={prize.name} />
          <div className="order-details-prize-name">{prize.name} ({prize.description})</div>
        </div>
      ))}
    </div>
    </>
  );

  if (accessDenied) {
    return (
      <div>
        <NavBar />
        <div className='gradient-background'>
          <div style={{ marginTop: "10px"}}>Access Denied</div>
        </div>
      </div>
    )
  }

  if (!order) {
    // This will cover both the case where order is initially null, and if the fetch fails to retrieve order details
    return (
      <div>
        <NavBar />
        <div className='gradient-background'>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  if (!order.paid) {
    return (
      <div>
        <NavBar />
        <div className='gradient-background'>
          <div style={{ marginTop: "10px"}}>Invalid Order Number</div>
        </div>
      </div>
    )
  }


  let shipByDate;

  if (order.shipByDate) {
    shipByDate = formatShipByDate(order.shipByDate);
  } else {
    // If shipByDate is null, set it to dateTimeCreated + 14 days
    const fallbackDate = new Date(order.dateTimeCreated);
    fallbackDate.setDate(fallbackDate.getDate() + 14);
    shipByDate = formatShipByDate(fallbackDate);
  }

  return (
    <div>
      <NavBar />
      <div className='gradient-background'>
        {showShipmentPopup && <ShipmentPopup
            orderIds={[order.orderId]} // Pass the single order in an array
            onCancel={() => setShowShipmentPopup(false)}
            userShippingAddress={userShippingAddress}
            setShowShipmentPopup={setShowShipmentPopup}
          />
        }
        {showSelfCollectPopup && <SelfCollectPopup
          onConfirm={confirmSelfCollect}
          onCancel={() => setShowSelfCollectPopup(false)}
        />}
        <h2 className="order-details-title">Order Details</h2>
        {/* Render detailed order information here */}
        <div className="order-card" key={order.orderId}>
            <div className="order-top">
                <div className="order-id">{order.orderId}</div>
                {order.shipped ? (
                    <div className="order-shipped">Shipped</div>
                ) : order.preorder ? (
                    <div className="order-not-shipped">PRE-ORDER</div>
                ) : (() => {
                    // Determine the effective shipByDate
                    let shipByDate;
                    if (order.shipByDate) {
                      shipByDate = new Date(order.shipByDate);
                    } else if (order.dateTimeCreated) {
                      shipByDate = new Date(order.dateTimeCreated);
                      shipByDate.setDate(shipByDate.getDate() + 14); // Add 14 days to dateTimeCreated
                    }

                    // Set shipByDate to the start of its day for comparison
                    const today = new Date();
                    today.setHours(0, 0, 0, 0); // Set to the start of the current day
                    if (shipByDate) {
                      shipByDate.setHours(0, 0, 0, 0); // Normalize to day-only comparison
                    }

                    // Check if the shipByDate has passed
                    return shipByDate && shipByDate < today ? (
                      <div className="order-expired">Expired</div>
                    ) : (
                      <div className="order-ship-button-group">
                        <button onClick={handleArrangeShipmentClick} className="order-not-shipped">Arrange Shipment</button>
                        <button onClick={handleSelfCollectClick} className="self-collect-button">Self-Collect</button> 
                      </div>
                    );
                })()}
            </div>
            <div className="order-content">
                <img src={`${backendUrl}/api/images/${order.productImage}`} alt="Product" />
                <div className="order-details">
                    <div className="product-name">{order.productName}</div>
                    <div className="ticket-calculation">
                        {
                          order.paymentIntentId == "TOKEN"
                          ? <div className="ticket-cost">{order.totalCost['$numberDecimal'] ? parseFloat(order.totalCost['$numberDecimal']/order.numberOfTickets) : 'N/A'} <img src={tokenIcon} alt="Token" className="order-token-icon" /></div>
                          : <div className="ticket-cost">${order.totalCost['$numberDecimal'] ? parseFloat(order.totalCost['$numberDecimal']/100/order.numberOfTickets).toFixed(2) : 'N/A'}</div>
                        }
                        <div className="number-of-tickets">x{order.numberOfTickets}</div>
                    </div>
                </div>
            </div>
            {
              order.paymentIntentId === "TOKEN"
              ? (<div className="order-details-total-cost">
                  Total: <strong>{order.totalCost['$numberDecimal']}</strong><img src={tokenIcon} alt="Token" className="order-token-icon" />
                </div>)
              : (<div className="order-details-total-cost">
                  Total: <strong>${order.totalCost['$numberDecimal'] ? parseFloat(order.totalCost['$numberDecimal']/100).toFixed(2) : 'N/A'}</strong>
                </div>)
            }
            {/* <div className="order-total-cost">
            Total: <strong>${order.totalCost['$numberDecimal'] ? parseFloat(order.totalCost['$numberDecimal']/100).toFixed(2) : 'N/A'}</strong>
            </div> */}
            <div className='order-extra'>
              <div className='order-date'>Order Date: {formatDateToSGT(order.dateTimeCreated)}</div>
              {order.shipped ? (
                <div className='shipment-details'>
                  Shipped to: {order.shipmentId.receiverName}, {order.shipmentId.receiverPostcode}, {order.shipmentId.receiverUnit}
                </div>
              ) : order.preorder ? (
                <div className='ship-by-date'>Ship By Date: Not Confirmed</div>
              ) : 
               (
                <div className='ship-by-date'>Ship By Date: {shipByDate}</div>
              )}     
            </div>
        </div>
        {order && (
          <React.Fragment>
            {/* Existing order card rendering */}
            {renderPrizes()}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default OrderDetailsPage;