import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import './TokenPaymentForm.css'; // Custom CSS for styling
import NavBar from './NavBar';
import tokenIcon from './surprize_token.png';
import { useSelector, useDispatch } from 'react-redux';
import { setPaymentState } from './redux/slices/paymentSlice';
import LoadingSpinner from './LoadingSpinner';

function TokenPaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [isLoading, setIsLoading] = useState(false);
  const [promoCode, setPromoCode] = useState(''); // State to store the promo code
  const [isPromoApplied, setIsPromoApplied] = useState(false); // State to track if promo is applied
  const [discountedPrice, setDiscountedPrice] = useState(null); // State for discounted price
  const [extraTokens, setExtraTokens] = useState(0); // State for extra tokens
  const [extraTokensPercentage, setExtraTokensPercentage] = useState(0); // State for extra tokens percentage
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages

  const dispatch = useDispatch();

  const tokenPackage = useSelector(state => state.payment.tokenPackage);
  const paymentIntentId = useSelector(state => state.payment.paymentIntentId);
  const username = useSelector(state => state.user.username);

  // Function to apply the promo code
  const applyPromoCode = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/promocode/apply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ 
          promoCode, 
          packageId: tokenPackage._id, 
          username,
          paymentIntentId  // Pass the paymentIntentId here
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        const { extraTokensPercentage } = data;
  
        const newTokenAmount = tokenPackage.tokenAmount + Math.round((tokenPackage.tokenAmount * extraTokensPercentage) / 100);
  
        setExtraTokens(newTokenAmount - tokenPackage.tokenAmount);
        setExtraTokensPercentage(extraTokensPercentage);
        setIsPromoApplied(true);
        setErrorMessage('');
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Failed to apply promo code');
        setIsPromoApplied(false);
      }
    } catch (error) {
      console.error('Error applying promo code:', error);
      setErrorMessage('Error applying promo code');
      setIsPromoApplied(false);
    }
  };  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }
  
    // Construct the return URL with query parameters
    const returnUrl = `${window.location.origin}/tokens`;
  
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl, // Use the URL with query parameters
      },
      redirect: 'if_required',
    });
  
    if (result.error) {
      setIsLoading(false);
      console.error(result.error.message);
    } else {
      if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
          // Check payment status before navigating
          handlePaymentStatusCheck(result.paymentIntent.id);
      }
    }
  };

  const handlePaymentStatusCheck = async (paymentIntentId) => {
    try {
        const response = await fetch(`${backendUrl}/api/token/payment-status/${paymentIntentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const data = await response.json();

        if (data.paymentStatus === 'succeeded') {
            navigate(`/tokens`);
        } else {
            console.log('Payment not yet completed. Retrying...');
            setTimeout(() => handlePaymentStatusCheck(paymentIntentId), 2000); // Retry after 2 seconds
        }
    } catch (error) {
        console.error('Error checking token payment status:', error);
    }
  };

  return (
    <div>
      <NavBar />
      {isLoading && (
            <div className="spinner-overlay">
              <LoadingSpinner />
              <p style={{color: "white"}}>Processing your payment...</p>
            </div>
      )}
      <div className="gradient-background">
        <div className="token-payment-info">
          <h2 className="token-payment-details">Payment Details</h2>
          <p className="token-payment-amount-description">
            <img src={tokenIcon} alt="Token" className="token-icon" />
            {isPromoApplied ? (
              <>
                <span className="original-token-amount">
                  <s>{tokenPackage.tokenAmount}</s>
                </span>
                &nbsp;
                <span className="new-token-amount">
                  {tokenPackage.tokenAmount + extraTokens}
                </span>
              </>
            ) : (
              <span>{tokenPackage.tokenAmount}</span>
            )}
          </p>
          <p className='token-payment-cost p-text'>
            Total Cost: ${(discountedPrice !== null ? discountedPrice : tokenPackage.price / 100).toFixed(2)}
          </p>
        </div>
        <div className="promo-code-section">
          <input
            type="text"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            placeholder="Enter promo code"
            className="promo-code-input"
            disabled={isPromoApplied} // Disable input if promo code is applied
          />
          <button
            onClick={applyPromoCode}
            className="apply-promo-button"
            disabled={isPromoApplied || !promoCode} // Disable if promo is applied or input is empty
          >
            {isPromoApplied ? 'Applied' : 'Apply'}
          </button>
        </div>
        {errorMessage && <p className="token-promo-error-message">{errorMessage}</p>} {/* Show error message if any */}
        {isPromoApplied && !errorMessage && (
          <p className="token-promo-success-message">{extraTokensPercentage}% more tokens!</p> /* Show the percentage extra tokens message */
        )}
        <div className="token-payment-container">
          <form className="token-stripe-payment-element-form" onSubmit={handleSubmit}>
            <PaymentElement />
            <button type="submit" className="token-pay-button" disabled={!stripe || isLoading}>
              {isLoading ? 'Processing...' : 'Pay'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TokenPaymentForm;