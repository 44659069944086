// AdminProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { setAuthState } from './redux/slices/authSlice';

function AdminProtectedRoute({ children }) {
  const dispatch = useDispatch();
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAdminAuthentication = () => {
      const token = localStorage.getItem('adminToken'); // Admin token stored separately

      console.log(token);
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp < currentTime) {
            console.log("Admin token expired.");
            dispatch(setAuthState({ isAuthenticated: false }));
            setIsAdminAuthenticated(false);
          } else if (decodedToken.role === 'admin') {
            // Ensure that the token belongs to an admin user
            setIsAdminAuthenticated(true);
            dispatch(setAuthState({ isAuthenticated: true }));
          } else {
            console.log("User is not an admin.");
            setIsAdminAuthenticated(false);
          }
        } catch (error) {
          console.error("Admin token invalid:", error);
          setIsAdminAuthenticated(false);
        }
      } else {
        console.log("No admin token found.");
        setIsAdminAuthenticated(false);
      }
      setIsLoading(false);
    };

    checkAdminAuthentication();
  }, [dispatch]);

  if (isLoading) {
    return <div>Authenticating...</div>; // Or some other loading indicator
  }

  return isAdminAuthenticated ? children : <Navigate to="/admin/login" />;
}

export default AdminProtectedRoute;